import { Country } from "@sixty-six-north/i18n"
import _isArray from "lodash/isArray"

export enum CountryToPath {
  dk = "dk",
  //  de = "de",
  eu = "eu",
  is = "is",
  gb = "uk",
  us = "us"
}

export enum PathToCountry {
  dk = "dk",
  //  de = "de",
  eu = "eu",
  is = "is",
  uk = "gb",
  us = "us"
}

export const countryToPath = (country: Country): string =>
  CountryToPath[country]

export const pathToCountry = (path?: string): Country | undefined => {
  if (!path) {
    return undefined
  } else if (_isArray(path.split("/"))) {
    const regionValueInPath: Country[] = new URL(
      path,
      "https://does-not-matter.com"
    ).pathname
      .split("/")
      .map(it => Country[PathToCountry[it]])
      .filter(it => it)
    return regionValueInPath[0]
  } else {
    return Country[PathToCountry[path]]
  }
}
